import {
  Box,
  Collapse,
  Flex,
  Grid,
  GridItem,
  Text,
  VStack,
} from "@chakra-ui/react";
import ProfileCard from "components/teams/ProfileCard";
import ProfileDetail from "components/teams/ProfileDetail";
import { useMemo, useRef, useState } from "react";

const OurTeams = () => {
  const [userIndex, setUserIndex] = useState<number>();
  const [advisoryIndex, setAdvisoryIndex] = useState<number>();
  const ref = useRef<HTMLDivElement>(null);
  const advisoryRef = useRef<HTMLDivElement>(null);
  const teams = [
    // {
    //   name: "HOANG NGUYEN (KING)",
    //   src: "/img/profile/MrKing.png",
    //   position: "Chairman, MIntA (Australia), CPA",
    //   description: [
    //     "Mr. King Nguyen has over 22 years of experience in the profession of strategic corporate finance and auditing. He has a Master of International Accounting and Finance from Swinburne University of Technology.",
    //     "He derives great satisfaction from helping clients establish investment and financial objectives. His selected job-done are as follows:",
    //   ],
    //   unorderedList: [
    //     "Advisory on 5 real estate projects owned by Vina Capital (period from 2015-2020).",
    //     "Assisted Ibus B.V Group in the sale of its assets in Vietnam to Minor International in 2013. The assets are now (1) Anantara Hoi An Resort (formerly Life Heritage Resort Hoi An), (2) Anantara Quy Nhon Villas (formerly Life Wellness Resort Quy Nhon).",
    //   ],
    // },
    {
      name: "MINH NGUYEN",
      src: "/img/profile/MrMinh.png",
      position: "Managing Partner",
      description: [
        "Mr. Minh has over 16 years of experience in the profession of corporate finance, financial advisory, tax and accounting advisory. Mr. Minh's extensive experience affords him the ability to assist the clients in the area of maximizing tax benefits and minimizing clients’ tax obligation. He also concentrates on finance management and M&A processes.",
        "The typical clients he has engaged in financial advisory services include the investments of VinaCapital Group (Dai Phuoc Lotus projects, Phu Hoi City projects, Vinacapital Hoi An Resort), DHL Global Vietnam Corporation, Anantara Resorts Group (formerly Life Resorts Group), Fusion Maia Resort, and the retail chains such as The Coffee House, Ut Ut Restaurant, West Way Dental Clinics.",
      ],
    },
    {
      name: "HUNG DANG",
      src: "/img/profile/MrHung.png",
      position: "Partner",
      description: [
        "Mr. Hung has over 15 years of experience in financial services with experience across due diligence, strategic corporate finance, M&A, financial restructuring and business advisory.",
        "Mr. Hung has been the CFO and member of the Board of Directors of our clients. He is able to be involved in strategic structure in capital and fund raising to meet their financial and business goals. In addition, he has been working closely with the investors and financial sponsors so that he is able to understand their interests in the relevant industries and to help our client’s earn the valuable investment and funding.",
      ],
    },
    {
      name: "PHUONG ANH TRAN",
      src: "/img/profile/MsAnh.png",
      position: "Director",
      description: [
        "Ms. Phuong Anh worked for Tax, Transfer Pricing and Deal Diligence services for more than 5 years. By leveraging her experience in various industries, she has provided solutions for many international companies and helped them address major challenges.",
        "The goal of H&Q’s transfer pricing advisory is to help companies manage risks by aligning practical transfer pricing solutions with their overall business operations and objectives, assist with strategic documentation to support their transfer pricing practices, and resolve disputes efficiently.",
      ],
    },
  ];

  const ourAdvisoryBoard = [
    {
      name: "VAN LUONG",
      src: "/img/profile/VanLuong2.jpg",
      position: "Advisory Partner",
      description: [
        "Ms. Van has more than 20 years of experience in training, organizational restructuring, company operation and investment consulting in the field of real estate. Experienced many positions such as international training manager, mainly responsible for Southeast Asia, South Asia for Target Corporation (USA), Lecturer at RMIT University _ Saigon South, and Deputy Director at Thu Thiem Real.",
        "In addition, she is the Founder and CEO of Mogin Holdings, Founder of Mogin Institute. Most recently, Ms. Van became the Advisory Partner of H&Q Advisory and participated in consulting programs on corporate restructuring, mergers and acquisitions.",
      ],
    },
    {
      name: "TUAN NGUYEN",
      src: "/img/profile/MrTuan.png",
      position: "Advisory Partner",
      description: [
        "Mr. Tuan is an experienced professional with 20+ years of expertise in estate strategy, investment consulting, and transaction structuring.",
        "He has an MBA from Solvay Brussels School and has worked with renowned developers like CapitaLand Vietnam and VietCapital Real Estate with a track record of successfully completing real estate M&A transactions valued at over US$200 million and deploying retail chains such as McDonald's and 7-Eleven. He also managed a private corporation operating a solar farm and rooftop installations with a capacity of up to 100MWp.",
        "Recently, Mr. Tuan joined H&Q Advisory as an Advisory Partner, specializing in corporate restructuring, mergers and acquisitions, and fundraising consulting programs.",
      ],
    },
  ];

  const handleSelectUser = (index: number) => {
    setUserIndex(index !== userIndex ? index : undefined);
    setAdvisoryIndex(undefined);
    setTimeout(() => {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }, 100);
  };

  const handleSelectAdvisory = (index: number) => {
    setAdvisoryIndex(index !== advisoryIndex ? index : undefined);
    setUserIndex(undefined);
    setTimeout(() => {
      advisoryRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }, 100);
  };

  const info = useMemo(() => {
    if (undefined === userIndex) return;

    return teams[userIndex];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userIndex]);
  const advisoryIndo = useMemo(() => {
    if (undefined === advisoryIndex) return;

    return ourAdvisoryBoard[advisoryIndex];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisoryIndex]);

  return (
    <Flex
      w={"100%"}
      minH="50rem"
      position={"relative"}
      margin="0 auto"
      justifyContent={"flex-start"}
      flexDirection="column"
      alignItems={"center"}
    >
      <VStack
        w={"100%"}
        px={{ base: "0", xl: "0", "2xl": "5rem" }}
        py="4rem"
        alignItems={"flex-start"}
      >
        <Box px={{ base: "2rem", md: 0 }}>
          <Text
            variant={"header"}
            fontWeight="bold"
            color="#122c6c"
            position={"relative"}
            _before={{
              content: "''",
              position: "absolute",
              left: "0",
              bottom: "0",
              top: "calc(100% + 10px)",
              height: "0.5rem",
              width: "80%",
              backgroundColor: "rgb(246, 142, 19) !important",
            }}
          >
            Our Team of Professionals
          </Text>
        </Box>
        <Grid
          alignSelf={"center"}
          my="5rem"
          templateColumns={{
            base: "repeat(1, 1fr)",
            lg: "repeat(3, 1fr)",
          }}
          rowGap={20}
          columnGap={15}
        >
          {teams.map((item, index) => (
            <GridItem onClick={() => handleSelectUser(index)}>
              <ProfileCard {...item} isSelected={index === userIndex} />
            </GridItem>
          ))}
        </Grid>
        <Box ref={ref}>
          <Collapse in={info ? true : false} animateOpacity>
            <ProfileDetail
              {...info}
              index={0}
              flexDirection={"row"}
              className="round-profile"
            />
          </Collapse>
        </Box>
        <Box position={"relative"} w="100%" mt="2rem">
          <Box
            w="100%"
            h="1rem"
            _before={{
              content: "''",
              display: "block",
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              width: "100%",
              height: "4rem",
              backgroundImage: "url('/img/dots-bg-repeat.png')",
              backgroundRepeat: "repeat-x",
              backgroundSize: "contain",
            }}
          ></Box>
        </Box>
        <Box px={{ base: "2rem", md: 0 }} pt="2rem">
          <Text
            variant={"header"}
            fontWeight="bold"
            color="#122c6c"
            position={"relative"}
            _before={{
              content: "''",
              position: "absolute",
              left: "0",
              bottom: "0",
              top: "calc(100% + 10px)",
              height: "0.5rem",
              width: "80%",
              backgroundColor: "rgb(246, 142, 19) !important",
            }}
          >
            Our Advisory Board
          </Text>
        </Box>
        <Grid
          alignSelf={"center"}
          my="5rem"
          templateColumns={{
            base: "repeat(1, 1fr)",
            md: "repeat(2, 1fr)",
          }}
          rowGap={20}
          columnGap={15}
        >
          {ourAdvisoryBoard.map((item, index) => (
            <GridItem
              maxW={"33rem"}
              onClick={() => handleSelectAdvisory(index)}
            >
              <ProfileCard {...item} isSelected={index === advisoryIndex} />
            </GridItem>
          ))}
        </Grid>

        <Box ref={advisoryRef}>
          <Collapse in={advisoryIndo ? true : false} animateOpacity>
            <ProfileDetail {...advisoryIndo} index={0} flexDirection={"row"} />
          </Collapse>
        </Box>
        <Box position={"relative"} w="100%" mt="2rem">
          <Box
            w="100%"
            h="1rem"
            _before={{
              content: "''",
              display: "block",
              position: "absolute",
              left: 0,
              right: 0,
              bottom: 0,
              width: "100%",
              height: "4rem",
              backgroundImage: "url('/img/dots-bg-repeat.png')",
              backgroundRepeat: "repeat-x",
              backgroundSize: "contain",
            }}
          ></Box>
        </Box>
      </VStack>
    </Flex>
  );
};

export default OurTeams;
